<template>
	<Container title="部署系统模块" :returnBtn="false" :addBtn="false" :onConfirm="onConfirm">
		<div
			style="width: 100%; display: flex; padding-bottom: 50px;height: 100%;overflow-y: hidden;flex-direction: column;overflow-x: hidden;">
			<el-form :rules="rules" :model="form" label-width="auto" label-position="top" ref="formName"
				style="display: flex; width: 100%;flex-wrap: wrap;">
				<el-form-item required label="菜单图标" style="width: 24%; margin: 0.5%">
					<el-select v-model="form.icon" placeholder="请选择菜单图标">
						<el-option v-for="(item, k) in icons" :key="'icon_' + k" :value="item.key">
							<el-icon>
								<component :is="item.icon"></component>
							</el-icon>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item required label="模块索引" style="width: 24%; margin: 0.5%" prop="dbname">
					<el-input v-model="form.dbname" placeholder="请填写模块索引" />
				</el-form-item>
				<el-form-item required label="菜单名称" style="width: 24%; margin: 0.5%" prop="name">
					<el-input v-model="form.name" placeholder="请填写菜单名称" />
				</el-form-item>

				<el-form-item required label="模块说明" style="width: 24%; margin: 0.5%" prop="desc">
					<el-input v-model="form.desc" placeholder="请填写模块说明" />
				</el-form-item>

				<el-form-item required label="编辑功能" style="width: 24%; margin: 0.5%">
					<el-select v-model="form.canedit" placeholder="选择编辑功能">
						<el-option label="是" :value="1" />
						<el-option label="否" :value="0" />
					</el-select>
				</el-form-item>
				<el-form-item required label="删除功能" style="width: 24%; margin: 0.5%">
					<el-select v-model="form.candel" placeholder="选择删除功能">
						<el-option label="是" :value="1" />
						<el-option label="否" :value="0" />
					</el-select>
				</el-form-item>
				<el-form-item required label="显示菜单" style="width: 24%; margin: 0.5%">
					<el-select v-model="form.showmenu" placeholder="选择是否显示菜单">
						<el-option label="是" :value="1" />
						<el-option label="否" :value="0" />
					</el-select>
				</el-form-item>
			</el-form>
			<div style="width: 100%;height: 1px;background: #e2e2e299;margin: 20px auto;"></div>
			<div style="width: 100%;display: flex;flex: 1;overflow: auto;flex-direction: column;background: #00aa7f11;border-radius: 10px;padding-top: 15px;">
				<el-form v-for="(item,k) in formFields" :key="`forfk_${k}`" :rules="rules" :model="formFields" label-width="auto" label-position="top" ref="formNameFields"
					style="display: flex; width: 95%;flex-wrap: wrap;padding:15px;border-radius:15px;border:1px solid #06219922;margin: 5px auto;">
					<el-form-item label="字段索引" style="width: 24%; margin: 0.5%" prop="name">
						<el-input :readonly="item.id" v-model="item.name" placeholder="请填写字段索引" />
					</el-form-item>

					<el-form-item label="字段名称" style="width: 24%; margin: 0.5%" prop="fieldname">
						<el-input :readonly="item.id" v-model="item.fieldname" placeholder="请填写字段名称" />
					</el-form-item>

					<el-form-item label="字段说明" style="width: 24%; margin: 0.5%" prop="fielddesc">
						<el-input :readonly="item.id" v-model="item.fielddesc" placeholder="请填写字段说明" />
					</el-form-item>

					<el-form-item label="列表筛选" style="width: 24%; margin: 0.5%">
						<el-select :disabled="item.id" v-model="item.showfilter" placeholder="选择列表筛选">
							<el-option label="否" value="否" />
							<el-option label="是" value="是" />
						</el-select>
					</el-form-item>

					<el-form-item label="字段数据类型" style="width: 24%; margin: 0.5%">
						<el-select :disabled="item.id" v-model="item.dbtype" placeholder="选择字段数据类型">
							<el-option label="SMALLINT" value="SMALLINT(5)" />
							<el-option label="INT(11)" value="INT(11)" />
							<el-option label="BIGINT" value="BIGINT" />
							<el-option label="FLOAT" value="FLOAT" />
							<el-option label="VARCHAR(10)" value="VARCHAR(10)" />
							<el-option label="VARCHAR(20)" value="VARCHAR(20)" />
							<el-option label="VARCHAR(30)" value="VARCHAR(30)" />
							<el-option label="VARCHAR(50)" value="VARCHAR(50)" />
							<el-option label="VARCHAR(100)" value="VARCHAR(100)" />
							<el-option label="VARCHAR(200)" value="VARCHAR(200)" />
							<el-option label="TEXT" value="TEXT" />
							<el-option label="LONGTEXT" value="LONGTEXT" />
						</el-select>
					</el-form-item>

					<el-form-item label="字段数据描述" style="width: 24%; margin: 0.5%">
						<el-select :disabled="item.id" v-model="item.dbdef" placeholder="选择字段数据类型">
							<el-option label="NOT NULL" value="NOT NULL" />
							<el-option label="DEFAULT 0" value="DEFAULT 0" />
							<el-option label="DEFAULT 1" value="DEFAULT 1" />
							<el-option label="DEFAULT NULL" value="DEFAULT NULL" />
							<!-- <el-option label="DEFAULT '<p></p>'" value="DEFAULT '<p></p>'" /> -->
							<el-option label="NOT NULL UNIQUE" value="NOT NULL UNIQUE" />
							
						</el-select>
					</el-form-item>
					
					<el-form-item label="字段渲染类型" style="width: 24%; margin: 0.5%">
						<el-select :disabled="item.id" v-model="item.type" placeholder="选择字段数据类型">
							<el-option label="string" value="string" />
							<el-option label="number" value="number" />
						</el-select>
					</el-form-item>
					
					<el-form-item label="字段渲染方式" style="width: 24%; margin: 0.5%">
						<el-select :disabled="item.id" v-model="item.rendertype" placeholder="选择字段渲染方式">
							<el-option label="string" value="string" />
							<el-option label="number" value="number" />
							<el-option label="date" value="date" />
							<el-option label="datetime" value="datetime" />
							<el-option label="enum" value="enum" />
							<el-option label="dict" value="dict" />
							<el-option label="database" value="database" />
							<el-option label="image" value="image" />
							<el-option label="file" value="file" />
							<el-option label="html" value="html" />
							<el-option label="map" value="map" />
							<el-option label="hide" value="hide" />
						</el-select>
					</el-form-item>

					<el-form-item v-if="item.rendertype == 'enum'" label="枚举数据" style="width: 24%; margin: 0.5%" prop="enums">
						<el-input v-model="item.enums" placeholder="请填写枚举数据" />
					</el-form-item>
					
					<el-form-item v-if="item.rendertype == 'dict'" label="数据字典选择" style="width: 24%; margin: 0.5%">
						<el-select :disabled="item.id" v-model="item.dict" placeholder="选择字段渲染方式">
							<el-option :key="`dop_${dk}`" v-for="(dictitem,dk) in $store.state.dict.dicts" :label="dictitem.name" :value="dictitem.dbname" />
						</el-select>
					</el-form-item>
					
					<el-form-item v-if="item.rendertype == 'database'" label="关联数据表选择" style="width: 24%; margin: 0.5%">
						<el-select :disabled="item.id" v-model="item.dbname" placeholder="选择关联数据表">
							<el-option :key="`dob_${dk}`" v-for="(dbitem,dk) in $store.state.database.list" :label="dbitem.dbtitle" :value="dbitem.dbname" />
						</el-select>
					</el-form-item>
					
				</el-form>
				
				<div @click="addfields" style="width: 95%;margin: 10px auto;border: 1px dotted #a7a7a7;border-radius: 10px;display: flex;align-items: center;justify-content: center;padding: 15px;">
					<el-icon size="30"><CirclePlus /></el-icon>
				</div>
			</div>
		</div>
	</Container>
</template>

<script>
	import moment from "moment";
	import {
		pub
	} from "/common/event";
	import {
		mapState
	} from "vuex";
import { useRoute } from "vue-router";
	export default {
		data: () => {
			return {
				form: {},
				formFields: [{}],
				rules: {},
				dbname:'',
				id:''
			};
		},
		mounted: function() {
			const route = useRoute();
			console.log(route.query);
			this.dbname = route.query.dbname
			this.id = route.query.id
			this.init()
		},
		computed: {
			...mapState({
				icons: (state) => {
					return state.icons.list;
				},
			}),
		},
		methods: {
			init: async function(){
				const ret = await this.$api.bgyx.getDbinfo(this.dbname)
				console.log(ret)
				this.form = ret.data.dbinfo
				this.formFields = ret.data.fields.map(e=>{
					const fd = {
						...e,
						rendertype:e.render
					}
					delete fd.render
					return fd
				})
			},
			onConfirm: async function() {
				console.log(this.form);
				console.log(this.formFields);
				const ret = await this.$api.bgyx.editnewmodel(this.form,this.formFields)
				console.log(ret);
				this.$store.dispatch({
				    type:"database/getList",
				    payload:{}
				})
				this.$router.push('/system/dbs')
			},
			addfields:function(){
				this.formFields.push({})
			},
		},
	};
</script>

<style scoped>
	.errorcss {
		border: 1px solid #ff352e88;
		border-radius: 3px;
	}
</style>