<template>
	<Container :title="`编辑${dbinfo.dbtitle}`" :returnBtn="false" :addBtn="false" :onConfirm="onConfirm">
		<div style="width: 100%;display: flex;padding-bottom: 50px;">
			<el-form :model="form" label-width="auto" label-position="top" ref="formName"
				style="display: flex;width: 100%;flex-direction: column;">
				<el-form-item v-for="(item,k) in fields" :key="`kmo_${k}`" :label="item.fieldname" style="width: 100%;">
					<div v-if="item.render == 'string' || item.render == 'hide'" style="width: 24%;margin: 0.5%;">
						<el-input v-model="form[item.name]" :placeholder="`填写${item.fieldname}`" />
					</div>
					<div v-if="item.render == 'number'" style="width: 24%;margin: 0.5%;">
						<el-input-number style="width: 100%;" v-model="form[item.name]" :placeholder="`填写${item.fieldname}`" />
					</div>
					<div v-if="item.render == 'date'" style="width: 24%;margin: 0.5%;">
						<el-date-picker @change="(rt)=>{
							this.form[item.name] = rt.valueOf()
						}" style="width: 100%;" v-model="form[item.name]" type="date"
							:placeholder="`填写${item.fieldname}`" />
					</div>
					<div v-if="item.render == 'datetime'" style="width: 24%;margin: 0.5%;">
						<el-date-picker @change="(rt)=>{
							this.form[item.name] = rt.valueOf()
						}" style="width: 100%;" v-model="form[item.name]" type="datetime"
							:placeholder="`填写${item.fieldname}`" />
					</div>

					<div v-if="item.render == 'enum'" style="width: 24%;margin: 0.5%;">
						<el-select v-model="form[item.name]" :placeholder="`填写${item.fieldname}`">
							<el-option v-for="(eitem,ek) in item.enums.split(',')" :key="`snfe_${ek}`" :label="eitem" :value="eitem" />
						</el-select>
					</div>
					
					<div v-if="item.render == 'dict'" style="width: 24%;margin: 0.5%;">
						<FormDictItem :name="item.name" :dbname="item.dict" :form="form" :fieldname="item.fieldname" />
					</div>
					<div v-if="item.render == 'database'" style="width: 24%;margin: 0.5%;">
						<FormDbItem :name="item.name" :dbname="item.dbname" :form="form" :fieldname="item.fieldname" />
					</div>
					<div v-if="item.render == 'image'" style="width: 24%;margin: 0.5%;">
						<FormImageItem :name="item.name" :form="form" :fieldname="item.fieldname" />
					</div>
					
					<div v-if="item.render == 'file'" style="width: 24%;margin: 0.5%;">
						<FormFileItem :name="item.name" :form="form" :fieldname="item.fieldname" />
					</div>
					
					<div v-if="item.render == 'map'" style="width: 100%;margin: 0.5%;">
						<FormMapItem :name="item.name" :form="form" :fieldname="item.fieldname" />
					</div>
					
					<div v-if="item.render == 'html'" style="width: 100%;margin: 0.5%;">
						<FormEditItem :value="form[item.name]" :form="form" :name="item.name" />
					</div>
				</el-form-item>


			</el-form>
		</div>
	</Container>
</template>

<script>
	import moment from 'moment'
	import {
		pub
	} from '../../../../common/event'
	import {
		useRoute
	} from 'vue-router';
	export default {
		data: () => {
			return {
				form: {},
				rules: {},
				dbname: '',
				fields: [],
				dbinfo: {},
				id:0
			}
		},
		mounted: function() {
			const route = useRoute();
			console.log(route.query);
			this.dbname = route.query.dbname
			this.id = route.query.id
			this.init()
		},
		methods: {
			onConfirm: async function() {
				console.log(this.form)
				const ret = await this.$api.bgyx.put(this.dbname,this.form)
				if(ret){
					pub("success_message","编辑成功")
					this.$router.push(`/bgyx/database/list?dbname=${this.dbname}`)
				}
			},
			init: async function() {
				const ret = await this.$api.bgyx.getDbinfo(this.dbname)
				console.log(ret)
				this.dbinfo = ret.data.dbinfo
				this.fields = ret.data.fields
				const dataret = await this.$api.bgyx.get(this.dbname,this.id)
				this.form = dataret.data
			},
		},
	}
</script>

<style scoped>
	.errorcss {
		border: 1px solid #ff352e88;
		border-radius: 3px;
	}
</style>