<template>
	<div style="width: 100%;">
		<el-upload
		  class="avatar-uploader"
		  :action="`${$store.state.config.host}:${$store.state.config.port}/upload/upload/image`"
		  :show-file-list="false"
		  :on-success="(e)=>{
			  this.form[this.name] = e.data
		  }" >
		  <div v-if="form[name]" style="display:flex;flex-direction:column;align-items: center;">
			  <el-icon><DocumentChecked /></el-icon>
			  <div>{{form[name]}}</div>
		  </div>
		  <i v-else class="el-icon-plus avatar-uploader-icon">
			  <el-icon><UploadFilled /></el-icon>
		  </i>
		</el-upload>
	</div>
</template>

<script>
	import {
		useStore
	} from 'vuex'

	export default {
		data() {
			return {
				list: [],
			}
		},
		props: {
			form: {
				type: Object
			},
			name: String,
			fieldname: String,
		},
		mounted() {
			this.init()
		},
		methods: {
			init: async function() {
				
			}
		}
	}
</script>