<template>
  <div style="
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow: hidden;
      ">
    <div style="
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding: 1px;
        ">
      <div style="
            width: 100%;
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 2px;
            flex: 1;
          ">
        <el-form :model="form" label-width="auto" label-position="top" ref="formName"
          style="display: flex; width: 100%; flex-wrap: wrap">
          <el-form-item label="名称" style="width: 24%; margin: 0.5%" prop="name">
            <el-input clearable v-model="form.name" placeholder="搜索名称" />
          </el-form-item>
          <el-form-item label="账号" style="width: 24%; margin: 0.5%" prop="appname">
            <el-input clearable v-model="form.account" placeholder="搜索账号" />
          </el-form-item>

          <el-form-item label="状态" style="width: 24%; margin: 0.5%" prop="status">
            <el-select v-model="form.status" placeholder="请选择状态">
              <el-option label="正常" value="1" />
              <el-option label="停用" value="0" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div style="
            padding: 20px;
            display: flex;
            align-items: center;
            margin-top: 25px;
          ">
        <div>
          <el-button @click="reset">重置</el-button>
        </div>
        <div style="margin-left: 5px">
          <el-button @click="search" type="primary">查询</el-button>
        </div>
      </div>
    </div>
    <div style="width: 100%; flex: 1; flex-direction: column; overflow-y: hidden">
      <div style="
            width: 100%;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            background: blue;
            height: 92%;
          ">
        <el-table :data="tableData" height="100%" style="width: 100%" :border="true" stripe :header-cell-style="stt">
          <el-table-column prop="account" label="账号" />
          <el-table-column prop="name" label="名称">
            <template #default="{ row }">
              {{ row.name }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态">
            <template #default="{ row }">
              <el-tag v-if="row.status == 1" type="success">正常</el-tag>
              <el-tag v-if="row.status == 0" type="danger">禁用</el-tag>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="160">
            <template #default="{ row }">
              <el-button type="primary" size="small" plain @click.stop="edit(row)">
                编辑
              </el-button>
              <el-popconfirm confirm-button-text="删除" cancel-button-text="取消" title="确定删除吗?" @confirm="del(row)">
                <template #reference>
                  <el-button type="danger" size="small" plain>删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="
            width: 100%;
            display: flex;
            height: 8%;
            justify-content: flex-end;
            align-items: center;
          ">
        <div style="margin-right: 10px">
          <el-pagination @change="pageChange" v-model:current-page="page.current" :page-size="page.pageSize"
            :total="page.total" background layout="prev, pager, next" />
        </div>
      </div>
    </div>

    <el-dialog v-model="dialogFormVisible" title="编辑系统用户数据" width="60%">
      <el-form :rules="rules" :model="formEdit" label-width="auto" label-position="top" ref="formName"
        style="display: flex; width: 100%; flex-direction: column">
        <el-form-item label="头像" style="width: 100%; margin: 0.5%">
          <el-upload class="avatar-uploader"
            :action="`${$store.state.config.host}:${$store.state.config.port}/upload/upload/image`"
            :show-file-list="false" :on-success="
              (e) => {
                this.formEdit.avatar = e.data;
              }
            ">
            <img v-if="formEdit.avatar" :src="formEdit.avatar" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon">
              <el-icon>
                <UploadFilled />
              </el-icon>
            </i>
          </el-upload>
        </el-form-item>
        <el-form-item required label="管理员账号" style="width: 24%; margin: 0.5%" prop="account">
          <el-input v-model="formEdit.account" placeholder="请填写管理员账号" />
        </el-form-item>
        <el-form-item required label="管理员名称" style="width: 24%; margin: 0.5%" prop="name">
          <el-input v-model="formEdit.name" placeholder="请填写管理员名称" />
        </el-form-item>
        <el-form-item required label="登录密码" style="width: 24%; margin: 0.5%" prop="password">
          <el-input v-model="formEdit.password" placeholder="请填写登录密码" />
        </el-form-item>

        <el-form-item required label="账号状态" style="width: 24%; margin: 0.5%">
          <el-select v-model="formEdit.status" placeholder="请选择状态">
            <el-option label="正常" :value="1" />
            <el-option label="停用" :value="0" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="confirmEdit">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { pub } from '../../../common/event';
export default {
  data: () => {
    return {
      form: {},
      formEdit: {},
      tableData: [],
      page: {
        current: 1,
        pageSize: 15,
        total: 15,
      },
      dialogFormVisible: false,
      rules: {},
      stt: {
        "background": "#209cd111",
        "color": "#209cd1"
      }
    };
  },
  props: {},
  mounted() {
    this.getData();
  },
  methods: {
    filterWhere: function () {
      const whereform = this.form;
      for (let k in whereform) {
        if (!whereform[k]) {
          delete whereform[k];
        }
      }
      const where = {
        current: this.page.current,
        pageSize: this.page.pageSize,
        ...whereform,
      };

      return where;
    },
    getData: async function () {
      const where = this.filterWhere();
      const ret = await this.$api.account.page(where);
      console.log(ret);
      if (ret && ret.success) {
        this.tableData = ret.data.data;
        this.page.total = ret.data.total;
        this.page.current = ret.data.page.current;
        this.page.pageSize = ret.data.page.pageSize;
      }

    },
    confirmEdit: async function (e) {
      console.log(this.formEdit);
      const ret = await this.$api.account.put(this.formEdit)
      this.dialogFormVisible = false
      pub("success_message", "编辑成功")
      this.getData();
    },
    edit: function (e) {
      console.log(e);
      this.formEdit = JSON.parse(JSON.stringify(e))
      this.dialogFormVisible = true;
    },
    del: async function (e) {
      console.log(e);
      const ret = await this.$api.account.del(e.id);
      pub("success_message", "删除成功")
      this.getData();
    },
    pageChange: function (e, v) {
      console.log(e, v);
      this.page.current = e;
      this.page.pageSize = v;
      this.getData();
    },
    reset: function () {
      this.form = {};
      this.$refs["formName"].resetFields();
      this.getData();
    },
    search: function () {
      this.getData();
    },
  },
};
</script>

<style></style>