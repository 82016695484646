import store from '../index'
import request from '../../common/request/index'
import { sub } from '../../common/event'

export default {
	namespaced: true,
	state: {
		systemInfo:{}
	},
	mutations: {
		setSystemInfoData:(state, payload)=>{
			state.systemInfo = payload
		},
	},
	actions: {
		async init({
			commit,
			state
		}, {
			payload
		}) {
			const ret = await request.api.get(`common/common/getSystemInfo`)
			commit(`setSystemInfoData`,ret.data)
		}
	},
	init: async function() {
		console.log("common初始化")
		store.dispatch({
			type:"common/init",
			payload:{}
		})

		sub("sysinfochange",()=>{
			store.dispatch({
				type:"common/init",
				payload:{}
			})
		})
	}
}