<template>
  <div style="
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                overflow: hidden;
              ">
    <div style="
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-items: flex-end;
                  padding: 1px;
                ">
      <div style="
                    width: 100%;
                    padding: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 2px;
                    flex: 1;
                  ">
        <el-form :model="form" label-width="auto" label-position="top" ref="formName"
          style="display: flex; width: 100%; flex-wrap: wrap">
          <el-form-item v-for="(item, k) in fields.filter((e) => e.showfilter == '是')" :key="`filter_${k}`"
            :label="item.fieldname" style="width: 24%; margin: 0.5%" :prop="item.name">
            <div v-if="item.render != 'dict' && item.render != 'database' && item.render != 'enum'" style="width: 100%">
              <el-input clearable v-model="form[item.name]" :placeholder="`搜索${item.fieldname}`" />
            </div>

            <div v-if="item.render == 'enum'" style="width: 100%">
              <el-select v-model="form[item.name]" :placeholder="`搜索${item.fieldname}`">
                <el-option v-for="(eitem, ek) in item.enums.split(',')" :key="`aedd_${ek}`" :label="eitem" :value="eitem" />
              </el-select>
            </div>

            <div v-if="item.render == 'dict'" style="width: 100%">
              <FormDictItem :name="item.name" :dbname="item.dict" :form="form" :fieldname="item.fieldname" />
            </div>
            <div v-if="item.render == 'database'" style="width: 100%">
              <FormDbItem :name="item.name" :dbname="item.dbname" :form="form" :fieldname="item.fieldname" />
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div style="
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    margin-top: 25px;
                  ">
        <div>
          <el-button @click="reset">重置</el-button>
        </div>
        <div style="margin-left: 5px">
          <el-button @click="search" type="primary">查询</el-button>
        </div>
      </div>
    </div>
    <div style="width: 100%; flex: 1; flex-direction: column; overflow-y: hidden">
      <div style="
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    overflow-y: auto;
                    background: blue;
                    height: 92%;
                  ">
        <el-table :data="tableData" height="100%" style="width: 100%" :border="true" stripe :header-cell-style="stt">
          <el-table-column v-for="(item, k) in fields" :key="`etc_${k}`" :prop="item.name" :label="item.fieldname">
            <template #default="{ row }">
              <div v-if="item.render == 'string'">
                {{ row[item.name] }}
              </div>
              <div v-if="item.render == 'enum'">
                {{ row[item.name] }}
              </div>
              <div v-if="item.render == 'number'">
                {{ row[item.name] }}
              </div>
              <div v-if="item.render == 'image'">
                <img :src="$store.state.common.systemInfo.fileurl + row[item.name]" style="width: 25px; height: 25px"
                  alt="" />
              </div>
              <div v-if="item.render == 'date'">
                {{ timeformat(row[item.name]) }}
              </div>
              <div v-if="item.render == 'datetime'">
                {{ datetimeformat(row[item.name]) }}
              </div>
              <div v-if="item.render == 'html'">-</div>
              <div v-if="item.render == 'hide'">-</div>
              <div v-if="item.render == 'dict'">
                {{ finddictname(row[item.name]) }}
              </div>
              <div v-if="item.render == 'database'" style="display:flex;">
                <div>{{ row[item.name + "_name"] }}</div> <el-tag>{{row[item.name]}}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="160">
            <template #default="{ row }">
              <el-button v-if="dbinfo.canedit == 1" type="primary" size="small" plain @click.stop="edit(row)">
                编辑
              </el-button>
              <el-popconfirm v-if="dbinfo.candel == 1" confirm-button-text="删除" cancel-button-text="取消" title="确定删除吗?"
                @confirm="del(row)">
                <template #reference>
                  <el-button type="danger" size="small" plain>删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="
                    width: 100%;
                    display: flex;
                    height: 8%;
                    justify-content: flex-end;
                    align-items: center;
                  ">
        <div style="margin-right: 10px">
          <el-pagination @change="pageChange" v-model:current-page="page.current" :page-size="page.pageSize"
            :total="page.total" background layout="prev, pager, next" />
        </div>
      </div>
    </div>

    <el-dialog v-model="dialogFormVisible" title="编辑数据" width="80%">
      <el-form :model="formEdit" label-width="auto" label-position="top" ref="formNameEdit" style="
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    height: 70vh;
                    overflow-y: auto;
                  ">
        <el-form-item v-for="(item, k) in fields" :key="`kmo_${k}`" :label="item.fieldname" style="width: 100%">
          <div v-if="item.render == 'string' || item.render == 'hide'" style="width: 24%; margin: 0.5%">
            <el-input v-model="formEdit[item.name]" :placeholder="`填写${item.fieldname}`" />
          </div>
          <div v-if="item.render == 'number'" style="width: 24%; margin: 0.5%">
            <el-input-number v-model="formEdit[item.name]" :placeholder="`填写${item.fieldname}`" />
          </div>
          <div v-if="item.render == 'datetime'" style="width: 24%; margin: 0.5%">
            <el-date-picker @change="
              (rt) => {
                this.form[item.name] = rt.valueOf();
              }
            " style="width: 100%" v-model="formEdit[item.name]" type="date" :placeholder="`填写${item.fieldname}`" />
          </div>
          <div v-if="item.render == 'dict'" style="width: 24%; margin: 0.5%">
            <FormDictItem :name="item.name" :dbname="item.dict" :form="formEdit" :fieldname="item.fieldname" />
          </div>
          <div v-if="item.render == 'database'" style="width: 24%; margin: 0.5%">
            <FormDbItem :name="item.name" :dbname="item.dbname" :form="formEdit" :fieldname="item.fieldname" />
          </div>
          <div v-if="item.render == 'image'" style="width: 24%; margin: 0.5%">
            <FormImageItem :name="item.name" :form="formEdit" :fieldname="item.fieldname" />
          </div>
          <div v-if="item.render == 'html'" style="width: 100%; margin: 0.5%">
            <FormEditItem :value="formEdit[item.name]" :form="formEdit" :name="item.name" />
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="confirmEdit"> 确定 </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { pub } from "../../../common/event";
import moment from "moment";
export default {
  data: () => {
    return {
      form: {},
      formEdit: {},
      tableData: [],
      dbinfo: {},
      fields: [],
      page: {
        current: 1,
        pageSize: 15,
        total: 15,
      },
      dialogFormVisible: false,
      rules: {},
      stt: {
        background: "#209cd111",
        color: "#209cd1",
      },
    };
  },
  props: {
    dbname: String,
  },
  mounted() {
    this.getData();
  },
  watch: {
    dbname: function () {
      this.getData()
    }
  },
  methods: {
    finddictname: function (e) {
      if (!e) {
        return "";
      }
      const datas = this.$store.state.dict.dictdatas;
      const fd = datas.find((ex) => ex.id == e);
      if (fd) {
        return fd["name"];
      } else {
        return "-";
      }
    },
    finddbname: async function (e, name) {
      if (!e) {
        return "";
      }
      let dbname = name;
      if (name.includes("bgyx_")) {
        dbname = dbname.replace(`bgyx_`, "");
      }
      const ret = await this.$api.bgyx.get(dbname, e);
      console.log(1105, ret);
      return ret.data?.name;
    },
    datetimeformat: function (e) {
      return moment(e).format("YYYY-MM-DD HH:mm:ss");
    },
    timeformat: function (e) {
      return moment(e).format("YYYY-MM-DD");
    },
    filterWhere: function () {
      const whereform = JSON.parse(JSON.stringify(this.form));
      for (let k in whereform) {
        if (!whereform[k]) {
          delete whereform[k];
        }
      }
      if (whereform["name"]) {
        whereform["name"] = {
          like: "%" + whereform["name"] + "%",
        };
      }
      const where = {
        current: this.page.current,
        pageSize: this.page.pageSize,
        ...whereform,
      };

      return where;
    },
    getData: async function () {
      // alert(this.dbname)
      const dbret = await this.$api.bgyx.getDbinfo(this.dbname);
      console.log(8899, dbret);
      this.dbinfo = dbret.data.dbinfo;
      this.fields = dbret.data.fields;
      const where = this.filterWhere();
      const ret = await this.$api.bgyx.bgyxpage(this.dbname, where);
      console.log("9966887", ret);
      if (ret && ret.success && ret.data) {
        this.tableData = ret.data.data;
        this.page.total = ret.data.total;
        this.page.current = ret.data.page.current;
        this.page.pageSize = ret.data.page.pageSize;
      }else{
        this.tableData = []
      }

    },
    confirmEdit: async function (e) {
      console.log(this.formEdit);
      const ret = await this.$api.account.put(this.formEdit);
      this.dialogFormVisible = false;
      pub("success_message", "编辑成功");
      this.getData();
    },
    edit: function (e) {
      console.log(e);
      // this.formEdit = JSON.parse(JSON.stringify(e))
      // this.dialogFormVisible = true;
      this.$router.push(`/bgyx/database/edit?dbname=${this.dbname}&id=${e.id}`);
    },
    del: async function (e) {
      console.log(e);
      const ret = await this.$api.bgyx.del(this.dbname, e.id);
      pub("success_message", "删除成功");
      this.getData();
    },
    pageChange: function (e, v) {
      console.log(e, v);
      this.page.current = e;
      this.page.pageSize = v;
      this.getData();
    },
    reset: function () {
      this.form = {};
      this.$refs["formName"].resetFields();
      this.getData();
    },
    search: function () {
      this.getData();
    },
  },
};
</script>

<style></style>